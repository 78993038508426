import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { Layout } from '../../constants';

import { getProfileCardSlot } from './slots';
import { getProfilePageBobWidgetRef } from './page-ref';

type ProfileCardPublicApi = {
  isHorizontalLayout(): Promise<boolean>;
  setHorizontalLayout(): Promise<void>;
  setSidebarLayout(): Promise<void>;
};

const PROFILE_CARD_APP_DEFINITION_ID = '14ce28f7-7eb0-3745-22f8-074b0e2401fb';

const PROFILE_CARD_WIDGET_ID = '14cefc05-d163-dbb7-e4ec-cd4f2c4d6ddd';

const getProfileCardPublicApi = (editorSDK: FlowEditorSDK) =>
  editorSDK.application.getPublicAPI('', {
    appDefinitionId: PROFILE_CARD_APP_DEFINITION_ID,
  }) as Promise<ProfileCardPublicApi | undefined>;

export const alignProfileCardLayout = async (editorSDK: FlowEditorSDK) => {
  const componentRef = await getProfilePageBobWidgetRef(editorSDK);
  const { layout } =
    await editorSDK.document.application.appStudioWidgets.getPreset('', {
      componentRef,
    });

  if (layout) {
    await setProfileCardLayout(editorSDK, layout as Layout);
  }
};

export const setProfileCardLayout = async (
  editorSDK: FlowEditorSDK,
  layoutPreset: Layout,
) => {
  const profileCardPublicApi = await getProfileCardPublicApi(editorSDK);

  switch (layoutPreset) {
    case Layout.Horizontal: {
      return profileCardPublicApi?.setHorizontalLayout();
    }
    case Layout.Mobile: {
      return profileCardPublicApi?.setHorizontalLayout();
    }
    case Layout.Vertical: {
      return profileCardPublicApi?.setSidebarLayout?.();
    }
  }
};

export const addProfileCardWidget = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const profileCardSlotComp = await getProfileCardSlot(editorSDK, widgetRef);
  if (!profileCardSlotComp) {
    throw new Error('Profile Card Slot is missing');
  }

  if (!profileCardSlotComp.pluginInfo) {
    return editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
      widgetPluginPointer: {
        appDefinitionId: PROFILE_CARD_APP_DEFINITION_ID,
        widgetId: PROFILE_CARD_WIDGET_ID,
      },
      slotCompRef: profileCardSlotComp.compRef,
    });
  }
};

export const removeProfileCardWidget = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const profileCardSlotComp = await getProfileCardSlot(editorSDK, widgetRef);
  if (!profileCardSlotComp) {
    throw new Error('Profile Card Slot is missing');
  }

  if (profileCardSlotComp.pluginInfo) {
    return editorSDK.tpa.widgetPlugins.removeWidgetPlugin('', {
      slotCompRef: profileCardSlotComp.compRef,
    });
  }
};
