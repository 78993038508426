import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { Layout } from '../../constants';
import { setProfileCardLayout } from './profile-card';
import { getProfilePageBobWidgetRef } from './page-ref';
import { maybeCollapseHeaderContainer } from './layout';

export const getLayoutPreset = async (editorSDK: FlowEditorSDK) => {
  const componentRef = await getProfilePageBobWidgetRef(editorSDK);
  if (!componentRef) {
    return null;
  }

  const { appStudioWidgets } = editorSDK.document.application;
  const layoutPreset = await appStudioWidgets.getPreset('', { componentRef });
  return layoutPreset.layout as Layout;
};

export const setLayoutPreset = async (
  editorSDK: FlowEditorSDK,
  layoutPreset: Layout,
) => {
  const componentRef = await getProfilePageBobWidgetRef(editorSDK);
  if (!componentRef) {
    return;
  }

  await setProfileCardLayout(editorSDK, layoutPreset);

  if (layoutPreset === Layout.Vertical) {
    await maybeCollapseHeaderContainer(editorSDK);
  }

  return editorSDK.document.application.appStudioWidgets.changePreset('', {
    componentRef,
    layoutPresetId: layoutPreset,
    stylePresetId: layoutPreset,
  });
};
