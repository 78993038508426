import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { getWidgetsIdsFromSlots } from '../../../editor/services/slots';
import { log, toMonitored } from '../../../editor/services/monitor';
import { getWidgetIdsFromSubMenu } from '../../../editor/services/members-sub-menu';
import { syncRoutes } from './sync-routes';
import { syncPlugins } from './sync-plugins';
import { getRoutesWidgetIds } from '../../../editor/controller/controllers-routes';
import { syncMenuBasedOnInstalledApps } from './sync-menu';

const getWidgetsIds = async (editorSDK: FlowEditorSDK) => {
  const [widgetIdsFromSubMenu, widgetIdsFromRoutes, widgetIdsFromSlots] =
    await Promise.all([
      getWidgetIdsFromSubMenu(editorSDK),
      getRoutesWidgetIds(editorSDK),
      getWidgetsIdsFromSlots(editorSDK),
    ]);

  // Followers item is not in the sub menu
  if (widgetIdsFromSlots.includes(WidgetId.FollowingFollowers)) {
    widgetIdsFromSubMenu.push(WidgetId.FollowingFollowers);
  }

  return {
    menuBasedWidgetIds: widgetIdsFromSubMenu,
    widgetIdsFromRoutes,
    widgetIdsFromSlots,
  };
};

export const syncDataBasedOnMenu = async (editorSDK: FlowEditorSDK) => {
  const { menuBasedWidgetIds, widgetIdsFromRoutes, widgetIdsFromSlots } =
    await getWidgetsIds(editorSDK);

  await syncPlugins({
    editorSDK,
    widgetIdsFromSlots,
    menuBasedWidgetIds,
  });

  await syncRoutes({
    editorSDK,
    widgetIdsFromRoutes,
    menuBasedWidgetIds,
  });
};

const monitoredSyncData = (editorSDK: FlowEditorSDK) => {
  return toMonitored('editorReady.sync-data', async () => {
    await syncMenuBasedOnInstalledApps(editorSDK);
    await syncDataBasedOnMenu(editorSDK);
  });
};

export const maybeSanitizeData = async (editorSDK: FlowEditorSDK) => {
  try {
    await monitoredSyncData(editorSDK);
  } catch (error) {
    log('Warning: Failed to sanitize data in editor ready', {
      extra: {
        error: typeof error === 'string' ? error : (error as Error).message,
      },
    });
  }
};
