import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { WidgetId } from '@wix/members-area-app-definitions';

import {
  removeSettingsPageSubMenuItems,
  updateSettingsPageSubMenuItemLabel,
} from './settings-page-sub-menu';
import {
  removeProfilePageSubMenuItems,
  updateProfilePageSubMenuItemLabel,
} from './profile-page-sub-menu';
import {
  removeLoginBarMenuItems,
  updateLoginBarMenuItemLabel,
} from './login-bar-menu';
import { removeLoginBarIconsMenuItems } from './login-bar-icons-menu';
import { refreshApp } from '../../../editor/editor-sdk-wrappers';

export const removeWidgetsMenusItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  return Promise.all([
    removeSettingsPageSubMenuItems(editorSDK, widgetsIds),
    removeProfilePageSubMenuItems(editorSDK, widgetsIds),
    removeLoginBarMenuItems(editorSDK, widgetsIds),
    removeLoginBarIconsMenuItems(editorSDK, widgetsIds),
  ]);
};

export const updateMenuItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  await Promise.all([
    updateProfilePageSubMenuItemLabel(editorSDK, widgetId, newLabel),
    updateSettingsPageSubMenuItemLabel(editorSDK, widgetId, newLabel),
    updateLoginBarMenuItemLabel(editorSDK, widgetId, newLabel),
  ]);

  // need to refresh for sub menu label update
  return refreshApp(editorSDK);
};
