import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type {
  IntegrationApplication,
  WidgetId,
} from '@wix/members-area-app-definitions';
import { InstallLocation } from '@wix/members-area-app-definitions';
import { debounce } from 'lodash';

import type {
  AddedWidgetConfig,
  WidgetPluginPointer,
  WidgetSlot,
} from '../../../types';

import {
  SETTINGS_SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
  SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
} from '../../../constants';
import {
  refreshApp,
  runAndWaitForApproval,
} from '../../../editor/editor-sdk-wrappers/document';
import { log, toMonitored } from '../../../editor/services/monitor';
import { globalAppState } from '../../../editor/services';
import {
  getProfilePageBobWidgetRef,
  getSettingsPageWidgetRef,
} from '../../../editor/services/page-ref';
import { getWidgetSlots } from '../../../editor/services/slots';
import { installProfilePageIfMissing } from '../installation';
import { updateProfilePageRoutes, updateSettingsPageRoutes } from './routes';
import { navigateToSection } from './navigation';

type DeboncedNavigateToAddedWidgetProps = {
  editorSDK: FlowEditorSDK;
  settingsAddedWidgetsConfigs: AddedWidgetConfig[];
  profileAddedWidgetsConfigs: AddedWidgetConfig[];
};

const groupDefinitionsByLocation = (definitions: IntegrationApplication[]) => {
  const definitionsMap = {
    settingsPage: [] as IntegrationApplication[],
    profilePage: [] as IntegrationApplication[],
  };

  definitions.forEach((definition) => {
    if (definition.installLocation === InstallLocation.Settings) {
      return definitionsMap.settingsPage.push(definition);
    }

    definitionsMap.profilePage.push(definition);
  });

  return definitionsMap;
};

const isPluginAlreadyAdded = (
  widgetsSlots: WidgetSlot[],
  widgetPluginPointer: WidgetPluginPointer,
) => {
  return widgetsSlots.some(({ pluginInfo }) => {
    return (
      pluginInfo?.widgetId === widgetPluginPointer.widgetId &&
      pluginInfo.appDefinitionId === widgetPluginPointer.appDefinitionId
    );
  });
};

const getNotInstalledDefinitions = (
  definitions: IntegrationApplication[],
  widgetSlots: WidgetSlot[],
) => {
  return definitions.reduce((notInstalledDefinitions, definition) => {
    return isPluginAlreadyAdded(widgetSlots, definition)
      ? notInstalledDefinitions
      : [...notInstalledDefinitions, definition];
  }, [] as IntegrationApplication[]);
};

const addWidgetPluginToProfilePage = async (
  editorSDK: FlowEditorSDK,
  definition: IntegrationApplication,
  slot: WidgetSlot,
) => {
  const {
    appDefinitionId,
    widgetId,
    visibleForRoles,
    socialHome,
    social,
    urlOverride,
  } = definition;

  await toMonitored(
    'ma-split.install.add-widget-plugin-profile',
    () => {
      return editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
        widgetPluginPointer: {
          appDefinitionId,
          widgetId,
        },
        slotCompRef: slot.compRef,
      });
    },
    {
      widgetPluginPointerAppDefinitionId: appDefinitionId,
      widgetPluginPointerWidgetId: widgetId,
      slotRole: slot.role,
      slotCompRefId: slot.compRef.id,
    },
  );

  return {
    widgetId,
    vfr: visibleForRoles,
    home: socialHome,
    private: !social,
    path: urlOverride!,
    state: SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE[slot.role],
  };
};

export const addWidgetPluginToSettingsPage = async (
  editorSDK: FlowEditorSDK,
  definition: IntegrationApplication,
  slot: WidgetSlot,
) => {
  const { appDefinitionId, widgetId, visibleForRoles } = definition;

  await toMonitored(
    'ma-split.install.add-widget-plugin-settings',
    () => {
      return editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
        widgetPluginPointer: {
          appDefinitionId,
          widgetId,
        },
        slotCompRef: slot.compRef,
      });
    },
    {
      widgetPluginPointerAppDefinitionId: appDefinitionId,
      widgetPluginPointerWidgetId: widgetId,
      slotRole: slot.role,
      slotCompRefId: slot.compRef.id,
    },
  );

  return {
    widgetId,
    vfr: visibleForRoles,
    path: definition.urlOverride!,
    state: SETTINGS_SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE[slot.role],
  };
};

const addWidgetPluginsToSettingsPage = async (
  editorSDK: FlowEditorSDK,
  definitions: IntegrationApplication[],
) => {
  const widgetRef = await getSettingsPageWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  // TODO: See if routes experiment pays of and use it.
  const emptySlots = widgetSlots.filter(({ pluginInfo }) => !pluginInfo);
  const addedWidgetsConfigs: AddedWidgetConfig[] = [];

  const addWidgetsAction = async () => {
    const addWidgetPromises = [];
    const notInstalledDefinitions = getNotInstalledDefinitions(
      definitions,
      widgetSlots,
    );

    for (const definition of notInstalledDefinitions) {
      const slot = emptySlots.shift();

      if (!slot) {
        log(
          `Settings Page: all available slots are taken, widget ${definition.widgetId} failed to install`,
        );
        break;
      }

      const addWidgetPromise = (async () => {
        const config = await addWidgetPluginToSettingsPage(
          editorSDK,
          definition,
          slot,
        );
        addedWidgetsConfigs.push(config);
      })();

      addWidgetPromises.push(addWidgetPromise);
    }

    return Promise.allSettled(addWidgetPromises);
  };

  await runAndWaitForApproval(editorSDK, addWidgetsAction);
  await updateSettingsPageRoutes(editorSDK, addedWidgetsConfigs);

  return addedWidgetsConfigs;
};

const addWidgetPluginsToProfilePage = async (
  editorSDK: FlowEditorSDK,
  definitions: IntegrationApplication[],
) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  // TODO: See if routes experiment pays of and use it.
  const emptySlots = widgetSlots.filter(({ pluginInfo }) => !pluginInfo);
  const addedWidgetsConfigs: AddedWidgetConfig[] = [];

  const addWidgetsAction = async () => {
    const addWidgetPromises = [];
    const notInstalledDefinitions = getNotInstalledDefinitions(
      definitions,
      widgetSlots,
    );

    for (const definition of notInstalledDefinitions) {
      const slot = emptySlots.shift();

      if (!slot) {
        log(
          `Profile Page: all available slots are taken, widget ${definition.widgetId} failed to install`,
        );
        break;
      }

      const addWidgetPromise = (async () => {
        const config = await addWidgetPluginToProfilePage(
          editorSDK,
          definition,
          slot,
        );
        addedWidgetsConfigs.push(config);
      })();

      addWidgetPromises.push(addWidgetPromise);
    }

    return Promise.allSettled(addWidgetPromises);
  };

  await runAndWaitForApproval(editorSDK, addWidgetsAction);
  await updateProfilePageRoutes(editorSDK, addedWidgetsConfigs);

  return addedWidgetsConfigs;
};

const debouncedMaybeNavigateToAddedWidget = debounce(
  async ({
    editorSDK,
    settingsAddedWidgetsConfigs,
    profileAddedWidgetsConfigs,
  }: DeboncedNavigateToAddedWidgetProps) => {
    const isSingleSettingWidget =
      settingsAddedWidgetsConfigs.length === 1 &&
      !profileAddedWidgetsConfigs.length;
    const isSingleProfileWidget =
      profileAddedWidgetsConfigs.length === 1 &&
      !settingsAddedWidgetsConfigs.length;

    if (isSingleSettingWidget || isSingleProfileWidget) {
      const widgetId = isSingleSettingWidget
        ? settingsAddedWidgetsConfigs[0].widgetId
        : profileAddedWidgetsConfigs[0].widgetId;

      return navigateToSection(editorSDK, widgetId);
    }
  },
  1000,
);

export const addWidgetsPlugins = async (
  editorSDK: FlowEditorSDK,
  definitions: IntegrationApplication[],
  shouldNavigate = false,
) => {
  const flowAPI = globalAppState.getFlowAPI()!;
  const definitionsByLocation = groupDefinitionsByLocation(definitions);

  const settingsPagePluginsAddPromise =
    definitionsByLocation.settingsPage.length > 0
      ? addWidgetPluginsToSettingsPage(
          editorSDK,
          definitionsByLocation.settingsPage,
        )
      : Promise.resolve([]);

  const profilePagePluginsAddPromise =
    definitionsByLocation.profilePage.length > 0
      ? installProfilePageIfMissing({ editorSDK, flowAPI }).then(() => {
          return addWidgetPluginsToProfilePage(
            editorSDK,
            definitionsByLocation.profilePage,
          );
        })
      : Promise.resolve([]);

  const [settingsAddedWidgetsConfigs, profileAddedWidgetsConfigs] =
    await Promise.all([
      settingsPagePluginsAddPromise,
      profilePagePluginsAddPromise,
    ]);
  await refreshApp(editorSDK);

  if (shouldNavigate) {
    // Need to debounce the navigation because the app properties are not updated yet (refreshApp uses debounce inside)
    return debouncedMaybeNavigateToAddedWidget({
      editorSDK,
      settingsAddedWidgetsConfigs,
      profileAddedWidgetsConfigs,
    });
  }
};

const removeWidgetPlugin = async (
  editorSDK: FlowEditorSDK,
  slotCompRef: ComponentRef,
) => {
  return editorSDK.tpa.widgetPlugins.removeWidgetPlugin('', {
    slotCompRef,
  });
};

const getInstalledPluginsSlots = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  return widgetSlots.filter(({ pluginInfo }) => pluginInfo?.widgetId);
};

const getInstalledWidgetsCompRefMap = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  const widgetSlots = await getInstalledPluginsSlots(editorSDK, widgetRef);

  return new Map(
    widgetSlots.map((widgetSlot) => [
      widgetSlot.pluginInfo!.widgetId,
      widgetSlot.compRef,
    ]),
  );
};

const getSlotsCompsRefsToRemove = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  widgetsIds: WidgetId[],
) => {
  const slotsCompsRefs = await getInstalledWidgetsCompRefMap(
    editorSDK,
    widgetRef,
  );

  return widgetsIds.reduce<ComponentRef[]>((componentRefs, widgetId) => {
    const slotCompRefToRemove = slotsCompsRefs.get(widgetId);

    return slotCompRefToRemove
      ? [...componentRefs, slotCompRefToRemove]
      : componentRefs;
  }, []);
};

export const removeWidgetPluginsFromWidget = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
  widgetIds: WidgetId[],
) => {
  const slotsCompsRefsToRemove = await getSlotsCompsRefsToRemove(
    editorSDK,
    widgetRef,
    widgetIds,
  );

  for (const slotCompRef of slotsCompsRefsToRemove) {
    await removeWidgetPlugin(editorSDK, slotCompRef);
  }
};
