import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import {
  GfppAction,
  HorizontalMenuRole,
  VERTICAL_LAYOUT_MENU_ROLE,
} from '../../../constants';
import { getSettingsPageWidgetRef } from '../../../editor/services/page-ref';
import type { TFunction } from '../../../types';
import { openSettingsManageAndNavigatePanel as _openSettingsManageAndNavigatePanel } from '../panels/open-panels';

export const getSettingsManageAndNavigateRefs = async (
  editorSDK: FlowEditorSDK,
) => {
  const widgetRef = await getSettingsPageWidgetRef(editorSDK);
  const [controllerRef] = await editorSDK.components.getChildren('', {
    componentRef: widgetRef,
  });
  if (!controllerRef) {
    throw new Error('controllerRef is missing');
  }

  const [horizontalComponentRef] = await editorSDK.components.findAllByRole(
    '',
    {
      controllerRef,
      role: HorizontalMenuRole.SettingsPage,
    },
  );

  if (horizontalComponentRef) {
    return { componentRef: horizontalComponentRef, controllerRef };
  }

  const [verticalComponentRef] = await editorSDK.components.findAllByRole('', {
    controllerRef,
    role: VERTICAL_LAYOUT_MENU_ROLE,
  });

  return { componentRef: verticalComponentRef, controllerRef };
};

export const openSettingsManageAndNavigatePanel = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
) => {
  const { componentRef, controllerRef } =
    await getSettingsManageAndNavigateRefs(editorSDK);

  await editorSDK.editor.selection.selectComponentByCompRef('', {
    compsToSelect: [componentRef],
  });

  const event = new CustomEvent<{
    id: GfppAction;
    componentRef: ComponentRef;
    controllerRef: ComponentRef;
  }>('', {
    detail: {
      id: GfppAction.OpenManageAndNavigatePanel,
      componentRef,
      controllerRef,
    },
  });

  return _openSettingsManageAndNavigatePanel({
    editorSDK,
    event,
    t,
  });
};
