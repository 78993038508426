import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { log } from '../../editor/services/monitor';

export const uninstallIfProvisionedWithoutMembersArea = async (
  editorSDK: FlowEditorSDK,
) => {
  const { isApplicationInstalled } = editorSDK.application;
  const isMembersAreaPlatformInstalled = await isApplicationInstalled('', {
    appDefinitionId: MEMBERS_AREA,
  });

  if (!isMembersAreaPlatformInstalled) {
    log(
      'Members Area Blocks platform is provisioned without Umbrella Platform - it will be deleted on editor ready',
    );
    await editorSDK.application.uninstall('', { openConfirmation: false });
    return { uninstalled: true };
  }

  return { uninstalled: false };
};
