import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { ComponentType, SerializedData } from '../../types';

const TOKEN = '';

export const findAllComponentsByType = (
  editorSDK: FlowEditorSDK,
  componentType: ComponentType,
) => {
  return editorSDK.components.findAllByType(TOKEN, {
    componentType,
  });
};

export const getComponentSerializedData = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
): Promise<SerializedData> => {
  return editorSDK.components.serialize(TOKEN, { componentRef });
};

export const getComponentData = async <T>(
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => editorSDK.components.data.get(TOKEN, { componentRef }) as Promise<T>;

export const getComponentsRefs = async (
  editorSDK: FlowEditorSDK,
  componentType: ComponentType,
) => {
  const compRefs = await findAllComponentsByType(editorSDK, componentType);

  return Promise.all(
    compRefs.map(async (compRef) => ({
      compRef,
      data: await getComponentData(editorSDK, compRef),
    })),
  );
};

export const updateComponentData = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  data: object,
) =>
  editorSDK.components.data.update(TOKEN, {
    componentRef,
    data,
  });

export const getAncestors = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  return editorSDK.components.getAncestors(TOKEN, {
    componentRef,
  });
};

export const getChildren = (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) =>
  editorSDK.components.getChildren(TOKEN, {
    componentRef,
  });

export const getType = (editorSDK: FlowEditorSDK, componentRef: ComponentRef) =>
  editorSDK.components.getType(TOKEN, { componentRef });

export const getComponentById = (editorSDK: FlowEditorSDK, id: string) =>
  editorSDK.components.getById(TOKEN, { id });
