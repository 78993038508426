import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  WidgetBuilder,
} from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId, Experiment, GfppAction } from '../../constants';
import { isProfilePageBobWidgetInstalled } from '../../editor/services/page-ref';

type ComponentBuilderFn = Parameters<
  WidgetBuilder['configureConnectedComponents']
>[1];

const getCollapsableElementsIds = async (editorSDK: FlowEditorSDK) => {
  if (await isProfilePageBobWidgetInstalled(editorSDK)) {
    return [ElementId.HeaderContainer, ElementId.MenuContainer];
  }

  return [ElementId.HeaderContainer];
};

const configureComponentAsCollapsable: ComponentBuilderFn = (builder) => {
  builder.behavior().set({ preventHide: false });
};

const configureWidgetGFPP = async (
  builder: WidgetBuilder,
  flowAPI: EditorScriptFlowAPI,
) => {
  const { t } = flowAPI.translations;
  const shouldAddUnifiedManagerGFPP = flowAPI.experiments.enabled(
    Experiment.EnableUnifiedManager,
  );

  if (shouldAddUnifiedManagerGFPP) {
    builder.gfpp().set('mainAction2', {
      label: t('app.settings-page.unified-manager.gfpp'),
      actionId: GfppAction.OpenSettingsUnifiedManagerPanel,
    });
  }

  builder.gfpp().set('help', {
    id: 'a26581e1-7f77-4094-b043-ee74b8c2c12a',
  });
  builder.configureConnectedComponents(
    'horizontalMenu',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: '4c8ba651-aa3f-45c2-82ae-abf3f856b3e6',
      });
      if (shouldAddUnifiedManagerGFPP) {
        connectedComponentsBuilder.gfpp().set('mainAction1', {
          label: t(
            'app.settings.manage.menu.panel.manage-and-navigate-renaming.cta',
          ),
          actionId: GfppAction.OpenSettingsManageAndNavigatePanel,
        });
        connectedComponentsBuilder.gfpp().set('mainAction2', {
          label: t('app.settings-page.horizontal-menu.unified-manager.gfpp'),
          actionId: GfppAction.OpenSettingsUnifiedManagerPanel,
        });
      }
    },
  );
  builder.configureConnectedComponents(
    'expandableMenu1',
    (connectedComponentsBuilder) => {
      connectedComponentsBuilder.gfpp().set('help', {
        id: '667fd515-1ffb-4879-9fa9-bad9a145aef6',
      });
      if (shouldAddUnifiedManagerGFPP) {
        connectedComponentsBuilder.gfpp().set('mainAction1', {
          label: t(
            'app.settings.manage.menu.panel.manage-and-navigate-renaming.cta',
          ),
          actionId: GfppAction.OpenSettingsManageAndNavigatePanel,
        });
        connectedComponentsBuilder.gfpp().set('mainAction2', {
          label: t('app.settings-page.expandable-menu.unified-manager.gfpp'),
          actionId: GfppAction.OpenSettingsUnifiedManagerPanel,
        });
      }
    },
  );
  builder.gfpp('mobile').set('mainAction1', { behavior: 'HIDE' });
  builder.gfpp().set('widgetPlugins', { behavior: 'HIDE' });
  builder.gfpp().set('stretch', { behavior: 'HIDE' });
};

const configurePageWidget = (builder: WidgetBuilder) => {
  builder.behavior().set({ removable: false, duplicatable: false });
};

export const getWidgetManifest: GetWidgetManifestFn = async (
  widgetBuilder,
  editorSDK,
  flowAPI,
) => {
  configureWidgetGFPP(widgetBuilder, flowAPI);
  configurePageWidget(widgetBuilder);

  const collapsableElementsIds = await getCollapsableElementsIds(editorSDK);

  collapsableElementsIds.forEach((elementId) => {
    widgetBuilder.configureConnectedComponents(
      elementId,
      configureComponentAsCollapsable,
    );
  });
};
