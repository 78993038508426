import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { addWidgetGfppClickedListener } from './gfpp';

export const addEventsListeners = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
) => {
  return Promise.all([addWidgetGfppClickedListener(editorSDK, flowAPI)]);
};
