import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getInstalledPluginsSlots } from '../../../editor/services/slots';
import { buildRoutesFromSlots } from '../../../editor/controller/routes-utils';

import { updateGlobalControllerWithRoutes } from '../../../editor/controller/global-controller';

/** @deprecated */
export const populateGlobalControllerWithRoutes = async (
  editorSDK: FlowEditorSDK,
) => {
  const slots = await getInstalledPluginsSlots(editorSDK);
  const routes = await buildRoutesFromSlots(editorSDK, slots);

  return updateGlobalControllerWithRoutes(editorSDK, routes);
};
