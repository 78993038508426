export enum GfppAction {
  OpenLayoutPanel = 'OpenLayoutPanel',
  OpenSettingsLayoutPanel = 'OpenSettingsLayoutPanel',
  OpenManageAndNavigatePanel = 'OpenManageAndNavigatePanel',
  OpenSettingsManageAndNavigatePanel = 'OpenSettingsManageAndNavigatePanel',
  OpenMemberPrivacySettingsBM = 'OpenMemberPrivacySettings',
  OpenDisplayPanel = 'OpenDisplayPanel',
  OpenSettingsDisplayPanel = 'OpenSettingsDisplayPanel',
  OpenUnifiedManagerPanel = 'OpenUnifiedManagerPanel',
  OpenSettingsUnifiedManagerPanel = 'OpenSettingsUnifiedManagerPanel',
}
