import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { WidgetId } from '@wix/members-area-app-definitions';

import { MenuId } from '../../../constants';
import { removeMenuItemsByWidgetIds, updateMenuItemLabel } from './menu';
import { getMenu } from '../../../editor/editor-sdk-wrappers';

export const removeSettingsPageSubMenuItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  return removeMenuItemsByWidgetIds(
    editorSDK,
    MenuId.SettingsSubMenu,
    widgetsIds,
  );
};

export const getSettingsPageSubMenu = async (editorSDK: FlowEditorSDK) => {
  return getMenu(editorSDK, MenuId.SettingsSubMenu);
};

export const updateSettingsPageSubMenuItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  return updateMenuItemLabel({
    editorSDK,
    menuId: MenuId.SettingsSubMenu,
    widgetId,
    newLabel,
  });
};
