import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { WidgetId } from '@wix/members-area-app-definitions';

import { MenuId } from '../../../constants';
import {
  getMenu,
  refreshApp,
  updateMenu,
} from '../../../editor/editor-sdk-wrappers';
import {
  filterOutMenuItemsByIds,
  getMenuDataWithUpdatedItems,
} from '../../../editor/services/utils';
import { getReorderedItems } from '../../../utils';
import type { EditorMenuItem } from '../../../types';

type ReorderMenuItemsProps = {
  editorSDK: FlowEditorSDK;
  menuId: MenuId;
  sourceIndex: number;
  targetIndex: number;
};

type UpdateMenuItemLabelProps = {
  editorSDK: FlowEditorSDK;
  widgetId: WidgetId;
  menuId: MenuId;
  newLabel: string;
};

export const removeMenuItemsByWidgetIds = async (
  editorSDK: FlowEditorSDK,
  menuId: MenuId,
  widgetsIds: WidgetId[],
) => {
  const menu = await getMenu(editorSDK, menuId);
  const filteredMenuItems = filterOutMenuItemsByIds(menu.items, widgetsIds);
  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    menu,
    filteredMenuItems,
  );

  return updateMenu(editorSDK, menuId, menuWithUpdatedItems);
};

export const reorderMenuItems = async ({
  editorSDK,
  menuId,
  sourceIndex,
  targetIndex,
}: ReorderMenuItemsProps) => {
  const menu = await getMenu(editorSDK, menuId);
  const reorderedMenuItems = getReorderedItems<EditorMenuItem>(
    sourceIndex,
    targetIndex,
    menu.items,
  );
  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    menu,
    reorderedMenuItems,
  );

  await updateMenu(editorSDK, menuId, menuWithUpdatedItems);

  return refreshApp(editorSDK);
};

export const updateMenuItemLabel = async ({
  editorSDK,
  menuId,
  newLabel,
  widgetId,
}: UpdateMenuItemLabelProps) => {
  const menu = await getMenu(editorSDK, menuId);
  const updatedMenuItems = menu.items?.map((menuItem) => {
    return menuItem?.link?.itemId === widgetId
      ? { ...menuItem, label: newLabel }
      : menuItem;
  });

  const menuWithUpdatedItems = getMenuDataWithUpdatedItems(
    menu,
    updatedMenuItems,
  );

  return updateMenu(editorSDK, menuId, menuWithUpdatedItems);
};
