import { WidgetInstallationType } from '@wix/platform-editor-sdk';

export const SANTA_MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const PROFILE_PAGE_BOB_STAGE_ID =
  'b976560c-3122-4351-878f-453f337b7245-mty3l';
export const PROFILE_PAGE_BOB_APP_DEF_ID =
  'b976560c-3122-4351-878f-453f337b7245';
export const PROFILE_PAGE_BOB_WIDGET_ID =
  '596a6688-3ad7-46f7-bb9c-00023225876d';
export const SETTINGS_PAGE_BOB_WIDGET_ID =
  '2f6c5608-393f-4b15-bfd8-d4e15396787a';
export const MY_ACCOUNT_PAGE_APP_DEF_ID =
  '14cffd81-5215-0a7f-22f8-074b0e2401fb';
export const MY_ACCOUNT_PAGE_WIDGET_ID = '14dd1af6-3e02-63db-0ef2-72fbc7cc3136';
export const MY_ACCOUNT_PATH = 'my-account';
export const APP_TOKEN = '';

export enum HostWidgetId {
  ProfilePage = '596a6688-3ad7-46f7-bb9c-00023225876d',
  SettingsPage = '2f6c5608-393f-4b15-bfd8-d4e15396787a',
}

export enum WidgetName {
  Widget1 = 'Widget1',
  SettingsPage = 'SettingsPage',
}

export enum Layout {
  Horizontal = 'variants-l9fvk04g',
  Vertical = 'variants-l694g6gm',
  Mobile = 'variants-l6921djl',
}

export enum SettingsLayout {
  Horizontal = 'variants-lr4xrdoc',
  Vertical = 'variants-lr955nek',
  Mobile = 'variants-lr955fnt',
}

const COMMON_WIDGET_OPTIONS = {
  layout: {
    x: 0,
    y: 0,
    fixedPosition: false,
    width: 980,
    height: 90,
    scale: 1,
    rotationInDegrees: 0,
  },
} as const;

export const SETTINGS_WIDGET_OPTIONS = {
  ...COMMON_WIDGET_OPTIONS,
  widgetId: SETTINGS_PAGE_BOB_WIDGET_ID,
  installationType: WidgetInstallationType.Closed,
  scopedPresets: {
    mobile: { layout: SettingsLayout.Mobile, style: SettingsLayout.Mobile },
    tablet: {
      layout: SettingsLayout.Horizontal,
      style: SettingsLayout.Horizontal,
    },
    desktop: {
      layout: SettingsLayout.Horizontal,
      style: SettingsLayout.Horizontal,
    },
  },
  presetIds: {
    layout: SettingsLayout.Horizontal,
    style: SettingsLayout.Horizontal,
  },
};

export const PROFILE_WIDGET_OPTIONS = {
  ...COMMON_WIDGET_OPTIONS,
  widgetId: PROFILE_PAGE_BOB_WIDGET_ID,
  installationType: WidgetInstallationType.Closed,
  scopedPresets: {
    mobile: { layout: Layout.Mobile, style: Layout.Mobile },
    tablet: { layout: Layout.Horizontal, style: Layout.Horizontal },
    desktop: { layout: Layout.Horizontal, style: Layout.Horizontal },
  },
  presetIds: { layout: Layout.Horizontal, style: Layout.Horizontal },
};
