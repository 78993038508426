import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getProfilePageBobWidgetRef } from '../../../editor/services/page-ref';
import { waitForMemberPageController } from '../../../editor/controller/member-page-controller';
import {
  GfppAction,
  HorizontalMenuRole,
  VERTICAL_LAYOUT_MENU_ROLE,
} from '../../../constants';
import type { TFunction } from '../../../types';
import { openManageAndNavigatePanel as _openManageAndNavigatePanel } from '../panels';

export const getManageAndNavigateRefs = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const controllerRef = await waitForMemberPageController(editorSDK, widgetRef);
  const [horizontalComponentRef] = await editorSDK.components.findAllByRole(
    '',
    {
      controllerRef,
      role: HorizontalMenuRole.ProfilePage,
    },
  );

  if (horizontalComponentRef) {
    return { componentRef: horizontalComponentRef, controllerRef };
  }

  const [verticalComponentRef] = await editorSDK.components.findAllByRole('', {
    controllerRef,
    role: VERTICAL_LAYOUT_MENU_ROLE,
  });

  return { componentRef: verticalComponentRef, controllerRef };
};

export const openManageAndNavigatePanel = async (
  editorSDK: FlowEditorSDK,
  t: TFunction,
) => {
  const { componentRef, controllerRef } = await getManageAndNavigateRefs(
    editorSDK,
  );

  await editorSDK.editor.selection.selectComponentByCompRef('', {
    compsToSelect: [componentRef],
  });

  const event = new CustomEvent<{
    id: GfppAction;
    componentRef: ComponentRef;
    controllerRef: ComponentRef;
  }>('', {
    detail: {
      id: GfppAction.OpenManageAndNavigatePanel,
      componentRef,
      controllerRef,
    },
  });

  return _openManageAndNavigatePanel({
    editorSDK,
    event,
    t,
  });
};
