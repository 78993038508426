import { log } from './monitor';

const DEFAULT_RETRY_COUNT = 3;
const DEFAULT_RETRY_DELAY = 250;

type WithRetryOptions = {
  methodName: string;
  retryCount?: number;
  retryDelay?: number;
};

const waitFor = (duration: number) => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

const logSuccessAfterRetry = (methodName: string, iteration: number) => {
  const message = `${methodName}: succeeded only when retrying, likely meaning an inconsistency in platform state`;
  const tags = { iteration, methodName };
  log(message, {
    tags,
  });
};

export const withRetry = async <T>(
  fn: () => Promise<T>,
  {
    methodName,
    retryCount = DEFAULT_RETRY_COUNT,
    retryDelay = DEFAULT_RETRY_DELAY,
  }: WithRetryOptions,
) => {
  for (
    let currentRetryCount = 0;
    currentRetryCount < retryCount;
    currentRetryCount++
  ) {
    if (currentRetryCount > 0) {
      await waitFor(currentRetryCount * retryDelay);
    }

    try {
      const result = await fn();

      if (currentRetryCount > 0) {
        logSuccessAfterRetry(methodName, currentRetryCount);
      }

      return result;
    } catch (error) {
      if (currentRetryCount + 1 >= retryCount) {
        throw error;
      }
    }
  }

  throw new Error('Max fetch retry count reached.');
};

export const waitForResult = <T>(
  fn: () => Promise<T | undefined>,
  methodName: string,
  maxRetries = 10,
  tick = 200,
) =>
  new Promise<T>(async (resolve, reject) => {
    const wait = async (iteration = 0) => {
      if (iteration >= maxRetries) {
        log(`"${methodName}" Error: waitFor timed out`);
        return reject(
          new Error(
            `${methodName} waitFor timed out after ${maxRetries} tries`,
          ),
        );
      }

      if (iteration <= maxRetries) {
        const result = await fn();
        if (result) {
          if (iteration > 0) {
            logSuccessAfterRetry(methodName, iteration);
          }
          resolve(result);
        } else {
          setTimeout(async () => {
            await wait(++iteration);
          }, tick);
        }
      }
    };

    try {
      await wait();
    } catch (err) {
      return reject(err);
    }
  });
