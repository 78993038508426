export enum ReferralInfo {
  MembersPrivacyManageTabsPanel = 'members_area_manage_tabs_panel',
  MembersPrivacyManageMemberPrivacyGfpp = 'members_area_manage_member_privacy_gfpp',
}

export enum SlugPlaceholder {
  UserName = '{userName}',
  My = 'my',
}

export const SLUG_PLACEHOLDERS: string[] = [
  SlugPlaceholder.UserName,
  SlugPlaceholder.My,
];
