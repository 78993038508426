import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

type Action<T> = () => T;

export const refreshApp = (editorSDK: FlowEditorSDK): Promise<void> => {
  return editorSDK.document.application.livePreview.refresh('', {
    source: `PUBLIC_API_REFRESH`,
    shouldFetchData: true,
  });
};

export const getPublicAPI = async <T>(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
) => {
  const api = await editorSDK.document.application.getPublicAPI('', {
    appDefinitionId,
  });

  if (!api) {
    throw new Error(`Failed to get public API for ${appDefinitionId}`);
  }

  return api as T;
};

export const runAndWaitForApproval = async <T>(
  editorSDK: FlowEditorSDK,
  action: Action<T>,
): Promise<ReturnType<Action<T>>> =>
  editorSDK.document.transactions.runAndWaitForApproval('', action);

export const isConflictError = (editorSDK: FlowEditorSDK, e: any) =>
  editorSDK.document.transactions.isConflictError('', e);
