import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { createCustomPanelOnClick } from '../../common/create-panel';
import { getMembersAreaUmbrellaPublicAPI } from '../../../editor/services/members-editor-script';
import { toMonitored } from '../../../editor/services/monitor';
import { withRetry } from '../../../editor/services';
import { WidgetName } from '../../../constants';
import type {
  ManageAndNavigatePanelInitialData,
  OpenManageAndNavigatePanelOptions,
  OpenUnifiedManagerPanelProps,
} from '../../../types';
import { getManageAndNavigatePanelInitialData } from './utils';

export const openUnifiedManagerPanel = async (
  editorSDK: FlowEditorSDK,
  params: OpenUnifiedManagerPanelProps,
) => {
  const membersAreaPublicAPI = await getMembersAreaUmbrellaPublicAPI(editorSDK);
  return membersAreaPublicAPI.openUnifiedManagerPanel(params);
};

export const openManageAndNavigatePanel = async ({
  editorSDK,
  event,
  t,
}: OpenManageAndNavigatePanelOptions) => {
  const initialData = await toMonitored(
    'manage-and-navigate-panel.get-initial-data',
    () =>
      withRetry(() => getManageAndNavigatePanelInitialData(editorSDK), {
        methodName: 'getManageAndNavigatePanelInitialData',
      }),
  );

  return createCustomPanelOnClick<ManageAndNavigatePanelInitialData>({
    title: t('app.settings.manage.menu.panel.title'),
    editorSDK,
    panelComp: 'ManageAndNavigatePanel',
    height: 521,
    width: 312,
    helpId: 'f99c3e77-0303-46f3-a5eb-23e565ecdcb8',
    initialData,
    widgetName: WidgetName.Widget1,
  })(event);
};
