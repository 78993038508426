import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorSDK } from '@wix/platform-editor-sdk';

import { MY_ACCOUNT_DEFINITION } from '../../constants';
import { addWidgetPlugin, getWidgetSlots } from './slots';
import { getProfilePageBobWidgetRef } from './page-ref';
import { log, toMonitored, transactionWithConflictMonitor } from './monitor';
import { waitForResult } from './retry-utils';
import { getMultiStateBoxRef } from './multi-state-box';
import { getMembersAreaPageRef } from './page';
import { addRoutesInAppProperties } from '../controller';

const validateSlots = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  try {
    const [unfilteredSlots, multiStateBoxRef, memberPageRef] =
      await Promise.all([
        editorSDK.tpa.widgetPlugins.getWidgetSlots('', { widgetRef }),
        getMultiStateBoxRef(editorSDK),
        getMembersAreaPageRef(editorSDK),
      ]);

    log('Error: No slots found for My Account plugin', {
      extra: {
        slots: JSON.stringify(unfilteredSlots),
        widgetRef,
        multiStateBoxRef,
        memberPageRef,
      },
    });
  } catch (e: any) {
    log('An error occured when validating slots in installation', {
      extra: {
        e: e.toString(),
      },
    });
  }
};

const getWidgetSlotsWithRetry = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
) => {
  try {
    const result = await waitForResult(
      async () => {
        const slots = await getWidgetSlots(editorSDK, widgetRef);
        return slots.length ? slots : undefined;
      },
      'getWidgetSlotsForMyAccount',
      20,
      400,
    );
    return result;
  } catch (e: any) {
    if (e.message.includes('waitFor timed out')) {
      // Not rethrowing as slot validation will throw later with more info
      return [];
    }
    throw e;
  }
};

// TODO: move my account to definitions
// Or restore to:
/* addWidgetsPlugins(
  { editorSDK, integrationApplications: [MY_ACCOUNT_DEFINITION] },
  { routeService },
);*/
export const addMyAccountWidget = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await toMonitored(
    'install.add-my-account-widget.getProfilePageBobWidgetRef',
    () => getProfilePageBobWidgetRef(editorSDK),
  );
  const widgetSlots = await toMonitored(
    'install.add-my-account-widget.getWidgetSlots',
    () => getWidgetSlotsWithRetry(editorSDK, widgetRef),
  );
  const emptySlot = widgetSlots.find(({ pluginInfo }) => !pluginInfo);

  if (!emptySlot) {
    await validateSlots(editorSDK, widgetRef);
    throw new Error('No empty slot was found for installing My Account widget');
  }

  const config = await toMonitored(
    'install.add-my-account-widget.addWidgetPlugin',
    () =>
      transactionWithConflictMonitor(editorSDK, 'add-my-account-widget', () =>
        addWidgetPlugin(editorSDK, MY_ACCOUNT_DEFINITION, emptySlot),
      ),
  );

  return toMonitored(
    'install.add-my-account-widget.createRouteConfigurations',
    () => addRoutesInAppProperties(editorSDK, [config]),
  );
};
