import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { WidgetId } from '@wix/members-area-app-definitions';

import { MenuId } from '../../../constants';
import { removeMenuItemsByWidgetIds, updateMenuItemLabel } from './menu';
import { getMenu } from '../../../editor/editor-sdk-wrappers';

export const removeProfilePageSubMenuItems = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  return removeMenuItemsByWidgetIds(
    editorSDK,
    MenuId.MembersSubMenu,
    widgetsIds,
  );
};

export const getProfilePageSubMenu = async (editorSDK: FlowEditorSDK) => {
  return getMenu(editorSDK, MenuId.MembersSubMenu);
};

export const updateProfilePageSubMenuItemLabel = async (
  editorSDK: FlowEditorSDK,
  widgetId: WidgetId,
  newLabel: string,
) => {
  return updateMenuItemLabel({
    editorSDK,
    menuId: MenuId.MembersSubMenu,
    widgetId,
    newLabel,
  });
};
