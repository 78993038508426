type SerializedError = {
  message?: string;
  stack?: string;
};

const NON_EXISTING_COMPONENT_POINTER_MESSAGE = `non existing component pointer`;

const isSerializedError = (
  serializedError?: any,
): serializedError is SerializedError => {
  return serializedError && 'message' in serializedError;
};

const getErrorMessageWithoutIdentifiers = (errorMessage: string) => {
  if (errorMessage.includes(NON_EXISTING_COMPONENT_POINTER_MESSAGE)) {
    return NON_EXISTING_COMPONENT_POINTER_MESSAGE;
  }
  return errorMessage;
};

export const toErrorInstance = (originalError: unknown) => {
  if (originalError instanceof Error) {
    return originalError;
  }

  if (typeof originalError === 'string' || originalError instanceof String) {
    return new Error(originalError.toString());
  }

  if (isSerializedError(originalError)) {
    const defaultErrorMessage = 'Caught error without message';
    const errorMessage = getErrorMessageWithoutIdentifiers(
      originalError.message ?? defaultErrorMessage,
    );

    const error = new Error(errorMessage);

    error.stack = originalError.stack ?? error.stack;

    return error;
  }

  return new Error('Unknown error');
};
