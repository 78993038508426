import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { AppExposedApis } from '@wix/platform-editor-sdk';

import { MEMBERS_AREA } from '@wix/app-definition-ids';

import { globalAppState } from '../../editor/services';
import { Experiment } from '../../constants';

export const createEditorEventsHandlers = (
  editorSDK: FlowEditorSDK,
): AppExposedApis['editor'] => ({
  // This app should be deleted from "beforeRemoveApp" handler in the umbrella platform app
  // However uninstallation gets stuck when done from there for some reason
  removeAppCompleted: async ({ appDefinitionId }) => {
    const flowAPI = globalAppState.getFlowAPI();

    const isUninstallMASubAppsEnabled = flowAPI
      ? flowAPI.experiments.enabled(Experiment.EnableUninstallMASubApps)
      : false;

    if (isUninstallMASubAppsEnabled) {
      return;
    }

    if (appDefinitionId === MEMBERS_AREA) {
      await editorSDK.application.uninstall('', { openConfirmation: false });
    }
  },
});
