import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import type { ControllerConfigPropMap } from '../../types';
import { RouteConfiguration } from '../../types';
import { getComponentById } from '../editor-sdk-wrappers/components';
import { setControllerConfigProp } from './utils';
import { CONTROLLER_PROP_CONFIG_KEY_ROUTES } from '../../constants';

const getMembersGlobalControllerRef = async (editorSDK: FlowEditorSDK) => {
  return getComponentById(editorSDK, 'CONTROLLER_COMP_CUSTOM_ID');
};

const getGlobalControllerConfigProp = async <
  T extends keyof ControllerConfigPropMap,
>(
  editorSDK: FlowEditorSDK,
  key: T,
): Promise<ControllerConfigPropMap[T] | undefined> => {
  const controllerRef = await getMembersGlobalControllerRef(editorSDK);

  if (!controllerRef) {
    return;
  }

  const data = await editorSDK.controllers.getData('', {
    controllerRef,
    scope: 'APP',
  });

  return data?.config?.[key];
};

/** @deprecated */
export const getRoutesFromGlobalController = async (
  editorSDK: FlowEditorSDK,
) => {
  const routes = await getGlobalControllerConfigProp(
    editorSDK,
    CONTROLLER_PROP_CONFIG_KEY_ROUTES,
  );

  return routes ?? [];
};

/** @deprecated */
export const updateGlobalControllerWithRoutes = async (
  editorSDK: FlowEditorSDK,
  routes: RouteConfiguration[],
) => {
  const controllerRef = await getMembersGlobalControllerRef(editorSDK);

  if (controllerRef) {
    await setControllerConfigProp({
      editorSDK,
      controllerRef,
      key: CONTROLLER_PROP_CONFIG_KEY_ROUTES,
      value: routes,
    });
  }
};

/** @deprecated */
export const removeRoutesFromGlobalController = async (
  editorSDK: FlowEditorSDK,
  widgetsIdsToRemove: WidgetId[],
) => {
  const currentRoutes = await getRoutesFromGlobalController(editorSDK);
  const filteredRoutes = currentRoutes.filter(
    (route) => !widgetsIdsToRemove.includes(route.widgetId),
  );

  if (filteredRoutes.length) {
    return updateGlobalControllerWithRoutes(editorSDK, filteredRoutes);
  }
};
