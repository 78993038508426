export enum MenuId {
  MembersSubMenu = 'MEMBERS_SUB_MENU',
  SettingsSubMenu = 'MEMBER_SETTINGS_SUB_MENU',
  LoginMenu = 'MEMBERS_LOGIN_MENU',
  LoginIconsMenu = 'MEMBERS_LOGIN_ICONS_MENU',
}

export enum ViewerMenuId {
  Horizontal = '#horizontalMenu1',
  Vertical = '#expandableMenu1',
}
