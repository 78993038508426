import type { CollapsedExpandedComponentRef } from '@wix/platform-editor-sdk';
import type { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { HostWidgetId } from '../../constants';

export const collapseRefComponent = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  await editorSDK.document.components.refComponents.collapseReferredComponent(
    '',
    { componentRef },
  );
};

const getComponentRef = async (
  editorSDK: FlowEditorSDK,
  hostWidgetId: HostWidgetId,
) => {
  const refComponents =
    await editorSDK.components.refComponents.getAllAppRefComponents('');

  const refComponentsDataPromisess = refComponents.map(async (ref) =>
    editorSDK.components.data.get('', {
      componentRef: ref,
    }),
  );

  const refComponentsData = await Promise.all(refComponentsDataPromisess);

  return refComponents.find((_, index) => {
    // @ts-expect-error
    return refComponentsData[index]?.widgetId === hostWidgetId;
  });
};

export const expandRefComponentByRole = async (
  editorSDK: FlowEditorSDK,
  hostWidgetId: HostWidgetId,
  role: string,
) => {
  const componentRef = await getComponentRef(editorSDK, hostWidgetId);

  if (!componentRef) {
    throw new Error(`Ref component not found based on: ${hostWidgetId}`);
  }

  // We're seeing undefined values instead of empty array coming from platform - fixing return type
  // To do: track the cases when this happens and report
  const allCollapsedComponents: (CollapsedExpandedComponentRef | undefined)[] =
    await editorSDK.components.refComponents.getCollapsedRefComponents('', {
      componentRef,
    });

  const collapsedCompToRestore = allCollapsedComponents.find(
    (comp) => comp?.role === role,
  );

  if (collapsedCompToRestore?.componentRef) {
    editorSDK.components.refComponents.expandReferredComponent('', {
      componentRef: collapsedCompToRestore.componentRef,
    });
  }
};

export const isRefComponentCollapsed = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
) => {
  const refApi = editorSDK.document.components.refComponents;
  return refApi.isRefComponentCollapsed('', { componentRef });
};
