import { SerializedBreakpointsData } from '@wix/document-services-types';

export const breakpoints: SerializedBreakpointsData = {
  type: 'BreakpointsData',
  values: [
    {
      type: 'BreakpointRange',
      id: 'members-area-v2-tablet-breakpoint',
      min: 320,
      max: 1000,
    },
    {
      type: 'BreakpointRange',
      id: 'members-area-v2-mobile-breakpoint',
      min: 320,
      max: 750,
    },
  ],
};
