import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'FFList',
  props: {
    members: {
      // @ts-expect-error
      type: undefined,
      defaultValue: [],
      title: 'List of members to display',
    },
  },
  methods: {},
  events: {
    memberFollowed: {
      description: 'Member followed another member',
    },
    memberUnfollowed: {
      description: 'Member unfollowed another member',
    },
    lastMemberShown: {
      description: 'Last member in the list is shown',
    },
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
