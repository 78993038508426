import { WidgetId } from '@wix/members-area-app-definitions';

import type { RouteConfiguration } from '../../types';
import { log } from '../../editor/services/monitor';

export const getRouteByWidgetId = (
  widgetId: WidgetId,
  routes: RouteConfiguration[],
) => routes.find((route) => widgetId === route.widgetId);

export const logManageAndNavigateMissingDataWarning = (extra: object) => {
  log(
    'Open Manage and Navigate panel: menu and routes are out of sync / missing data',
    {
      extra,
    },
  );
};
