export const COMPONENTS_REF = 'wysiwyg.viewer.components.RefComponent';
export const COMPONENTS_SECTION = 'wysiwyg.viewer.components.ClassicSection';
export const COMPONENTS_SECTION_RESPONSIVE = 'responsive.components.Section';

export enum HorizontalMenuRole {
  SettingsPage = 'horizontalMenu',
  ProfilePage = 'horizontalMenu1',
}

export const VERTICAL_LAYOUT_MENU_ROLE = 'expandableMenu1';
