import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { PageData, PageRef } from '@wix/platform-editor-sdk';

import { PagesOptions } from '../../types';
import { getMembersAreaPageRef } from '../services/page';

interface PageRenameOptions {
  title: string;
  pageRef: PageRef;
}

export const addPage = async (
  editorSDK: FlowEditorSDK,
  options: PagesOptions,
) => editorSDK.pages.add('', options);

export const renamePage = async (
  editorSDK: FlowEditorSDK,
  options: PageRenameOptions,
) => editorSDK.pages.rename('', options);

export const updatePageData = (
  editorSDK: FlowEditorSDK,
  options: { pageRef: PageRef; data: Partial<PageData> },
) => editorSDK.pages.data.update('', options);

export const getPageData = (editorSDK: FlowEditorSDK, pageRef: PageRef) =>
  editorSDK.pages.getPageData('', { pageRef });

export const getMemberPageUriSEO = async (editorSDK: FlowEditorSDK) => {
  const pageRef = await getMembersAreaPageRef(editorSDK);
  const { pageUriSEO } = await getPageData(editorSDK, pageRef);

  return pageUriSEO;
};
