import { toMonitored } from '../../editor/services/monitor';
import {
  addMyAccountWidget,
  addProfilePageBobWidget,
} from '../../editor/services';
import { PlatformContext } from '../../types';
import { Experiment } from '../../constants';

export const installMemberPage = async ({
  editorSDK,
  options,
  appDefinitionId,
  flowAPI,
}: PlatformContext) => {
  await toMonitored('install.add-profile-page-bob-widget', () =>
    addProfilePageBobWidget({
      editorSDK,
      options,
      appDefinitionId,
      flowAPI,
    }),
  );

  if (flowAPI.experiments.enabled(Experiment.EnableMyAccountParallelInstall)) {
    return Promise.resolve();
  }

  return toMonitored('install.add-my-account-widget', () =>
    addMyAccountWidget(editorSDK),
  );
};
