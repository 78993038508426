import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';

import {
  getSettingsPageRef,
  isSettingsPageInstalled,
} from '../../editor/services/page';
import { isSettingsPageBobWidgetInstalled } from '../../editor/services/page-ref';
import { Experiment } from '../../constants';

export const shouldUseSplitPlatformApp = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options?: Partial<{ firstInstall: boolean }>,
) => {
  const settingsPageRef = await getSettingsPageRef(editorSDK);
  const isSplitEnabled = flowAPI.experiments.enabled(
    Experiment.SplitInstallation,
  );

  if (settingsPageRef) {
    return true;
  }

  if (options?.firstInstall) {
    return isSplitEnabled;
  }

  return false;
};

export const isSplitPlatformAppValid = async (editorSDK: FlowEditorSDK) => {
  try {
    return (
      (await isSettingsPageInstalled(editorSDK)) &&
      (await isSettingsPageBobWidgetInstalled(editorSDK))
    );
  } catch (e) {
    return false;
  }
};
