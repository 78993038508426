import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Widget1',
  props: {},
  methods: {},
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
