import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { MenuData } from '../../types';
import { globalAppState } from '../services';
import { Experiment } from '../../constants';
import { refreshApp } from './document';

export const getMenu = async (editorSDK: FlowEditorSDK, menuId: string) => {
  const menuData = await editorSDK.menu.getById('', { menuId });
  return menuData as MenuData;
};

export const updateMenu = async (
  editorSDK: FlowEditorSDK,
  menuId: string,
  menuData: MenuData,
) => {
  const flowAPI = globalAppState.getFlowAPI();

  await editorSDK.menu.update('', { menuId, menuData });

  if (
    flowAPI?.experiments.enabled(Experiment.VerticalDeletionRemoveRefreshApp)
  ) {
    return;
  }

  return refreshApp(editorSDK);
};
