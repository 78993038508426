import type { EditorPlatformApp } from '../../types/editor';

import { editorReady } from './editor-ready';
import { getAppManifest } from './app-manifest';
import { createPublicAPI } from './api-exports/public-api';
import { createPrivateAPI } from './api-exports/private-api';

export { shouldUseSplitPlatformApp } from './utils';

export const splitApp: EditorPlatformApp = {
  editorReady,
  getAppManifest,
  exports: {
    public: createPublicAPI,
    private: createPrivateAPI,
  },
};
