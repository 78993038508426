import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import type { PageRef } from '@wix/platform-editor-sdk';
import type { WidgetId } from '@wix/members-area-app-definitions';

import type { PlatformContext, RouteConfiguration } from '../../../types';

import {
  EDITOR_X_WIDGET_LAYOUT,
  OLD_EDITOR_X_WIDGET_LAYOUT,
} from '../../../constants/editor-x';
import {
  Experiment,
  PROFILE_WIDGET_OPTIONS,
  SETTINGS_WIDGET_OPTIONS,
} from '../../../constants';
import { addWidget } from '../../../editor/editor-sdk-wrappers';
import {
  toMonitored,
  transactionWithConflictMonitor,
} from '../../../editor/services/monitor';
import {
  createProfilePage,
  createSettingsPage,
  findSectionInPage,
} from '../../../editor/services/page';
import {
  getProfilePageBobWidgetRef,
  getSettingsPageWidgetRef,
} from '../../../editor/services/page-ref';
import {
  getProfilePageRoutes,
  getSettingsPageRoutes,
  removeRoutesFromProfilePage,
  removeRoutesFromSettingsPage,
} from './routes';
import { removeWidgetPluginsFromWidget } from './slots';
import { removeWidgetsMenusItems } from './menu-items';

const getSectionRef = (
  context: Pick<PlatformContext, 'editorSDK' | 'flowAPI'>,
  pageRef: PageRef,
) => {
  const { environment } = context.flowAPI;

  if (environment.isEditorX) {
    return;
  }

  return findSectionInPage(context, pageRef);
};

export const addMemberSettingsPage = async (
  context: Pick<PlatformContext, 'flowAPI' | 'editorSDK'>,
) => {
  const action = async () => {
    const pageRef = await toMonitored(
      'ma-split.install.create-profile-page',
      () => createSettingsPage(context),
    );
    const sectionRef = await toMonitored(
      'ma-split.install.get-section-ref',
      () => getSectionRef(context, pageRef),
    );
    const containerRef = sectionRef || pageRef;

    const enableGridAreaFix = context.flowAPI.experiments.enabled(
      Experiment.EnableGridAreaFix,
    );
    const layouts = enableGridAreaFix
      ? EDITOR_X_WIDGET_LAYOUT
      : OLD_EDITOR_X_WIDGET_LAYOUT;

    return addWidget(context.editorSDK, {
      ...SETTINGS_WIDGET_OPTIONS,
      layouts,
      containerRef,
    });
  };

  return transactionWithConflictMonitor(
    context.editorSDK,
    'ma-split.add-settings-bob-widget',
    action,
  );
};

export const addProfilePageWidget = async (
  context: Pick<PlatformContext, 'flowAPI' | 'editorSDK'>,
) => {
  const { flowAPI, editorSDK } = context;
  const { t } = flowAPI.translations;

  const transaction = async () => {
    const profilePageRef = await toMonitored(
      'ma-split.install.create-profile-page',
      () => {
        return createProfilePage({
          flowAPI,
          editorSDK,
          title: t('app.page.profile.title'),
          pageUriSEO: 'profile',
        });
      },
    );
    const sectionRef = await toMonitored(
      'ma-split.install.get-section-ref',
      () => getSectionRef(context, profilePageRef),
    );
    const containerRef = sectionRef || profilePageRef;

    const enableGridAreaFix = context.flowAPI.experiments.enabled(
      Experiment.EnableGridAreaFix,
    );
    const layouts = enableGridAreaFix
      ? EDITOR_X_WIDGET_LAYOUT
      : OLD_EDITOR_X_WIDGET_LAYOUT;

    return addWidget(editorSDK, {
      ...PROFILE_WIDGET_OPTIONS,
      layouts,
      containerRef,
    });
  };

  return transactionWithConflictMonitor(
    editorSDK,
    'ma-split.add-profile-page-bob-widget',
    transaction,
  );
};

const groupWidgetIdsByLocation = (
  widgetIds: WidgetId[],
  settingsRoutes: RouteConfiguration[],
  profileRoutes: RouteConfiguration[],
) => {
  const settingsWidgets: WidgetId[] = [];
  const profileWidgets: WidgetId[] = [];

  widgetIds.forEach((widgetId) => {
    if (settingsRoutes.some((route) => route.widgetId === widgetId)) {
      settingsWidgets.push(widgetId);
    } else if (profileRoutes.some((route) => route.widgetId === widgetId)) {
      profileWidgets.push(widgetId);
    }
  });

  return { settingsWidgets, profileWidgets };
};

export const removeWidgetPlugins = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const [settingsRoutes, profileRoutes] = await Promise.all([
    getSettingsPageRoutes(editorSDK),
    getProfilePageRoutes(editorSDK),
  ]);
  const { profileWidgets, settingsWidgets } = groupWidgetIdsByLocation(
    widgetsIds,
    settingsRoutes,
    profileRoutes,
  );
  const [profilePageWidgetRef, settingsPageWidgetRef] = await Promise.all([
    profileRoutes.length > 0 ? getProfilePageBobWidgetRef(editorSDK) : null,
    settingsRoutes.length > 0 ? getSettingsPageWidgetRef(editorSDK) : null,
  ]);

  if (settingsPageWidgetRef && settingsWidgets.length > 0) {
    await removeWidgetPluginsFromWidget(
      editorSDK,
      settingsPageWidgetRef,
      widgetsIds,
    );
  }

  if (profilePageWidgetRef && profileWidgets.length > 0) {
    await removeWidgetPluginsFromWidget(
      editorSDK,
      profilePageWidgetRef,
      widgetsIds,
    );
  }

  await removeWidgetsMenusItems(editorSDK, widgetsIds);
  await removeRoutesFromSettingsPage(editorSDK, widgetsIds);
  await removeRoutesFromProfilePage(editorSDK, widgetsIds);
};
