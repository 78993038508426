import { debounce } from 'lodash';
import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  getAppDefIdFromWidgetId,
  IntegrationApplication,
  WidgetId,
} from '@wix/members-area-app-definitions';

import {
  AddedWidgetConfig,
  WidgetPluginPointer,
  WidgetSlot,
} from '../../types';
import {
  Experiment,
  SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
} from '../../constants';
import {
  refreshApp,
  runAndWaitForApproval,
} from '../editor-sdk-wrappers/document';
import { getProfilePageBobWidgetRef } from './page-ref';
import { navigateToSection } from './navigation';
import { log, toMonitored } from './monitor';
import { getWidgetIdsSetFromSubMenu } from './members-sub-menu';
import { getRoutes } from '../controller/controllers-routes';
import { globalAppState } from './global-app-state';
import { addRoutesInAppProperties } from '../controller';

const PROFILE_CARD_SLOT_ROLE = 'profileCardSlot';

type AddWidgetsPluginsContextProps = {
  editorSDK: FlowEditorSDK;
  integrationApplications: IntegrationApplication[];
  shouldNavigate?: boolean;
};

const getAllWidgetSlots = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  return (
    await editorSDK.tpa.widgetPlugins.getWidgetSlots('', { widgetRef })
  ).filter((slot) => !!slot) as WidgetSlot[];
};

export const getWidgetSlots = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  const widgetSlots = await getAllWidgetSlots(editorSDK, widgetRef);
  return widgetSlots.filter((slot) => slot.role !== PROFILE_CARD_SLOT_ROLE);
};

export const getProfileCardSlot = async (
  editorSDK: FlowEditorSDK,
  widgetRef: ComponentRef,
) => {
  const widgetSlots = await getAllWidgetSlots(editorSDK, widgetRef);
  return widgetSlots.find((slot) => slot.role === PROFILE_CARD_SLOT_ROLE);
};

const isPluginAlreadyAdded = (
  widgetsSlots: WidgetSlot[],
  widgetPluginPointer: WidgetPluginPointer,
) =>
  widgetsSlots.some(
    ({ pluginInfo }) =>
      pluginInfo?.widgetId === widgetPluginPointer.widgetId &&
      pluginInfo.appDefinitionId === widgetPluginPointer.appDefinitionId,
  );

export const addWidgetPlugin = async (
  editorSDK: FlowEditorSDK,
  integrationApplication: IntegrationApplication,
  slot: WidgetSlot,
) => {
  const { appDefinitionId, widgetId, visibleForRoles, socialHome, social } =
    integrationApplication;

  await toMonitored(
    'install.add-widget-plugin',
    () => {
      return editorSDK.tpa.widgetPlugins.addWidgetPlugin('', {
        widgetPluginPointer: {
          appDefinitionId,
          widgetId,
        },
        slotCompRef: slot.compRef,
      });
    },
    {
      widgetPluginPointerAppDefinitionId: appDefinitionId,
      widgetPluginPointerWidgetId: widgetId,
      slotRole: slot.role,
      slotCompRefId: slot.compRef.id,
    },
  );

  return {
    widgetId,
    vfr: visibleForRoles,
    home: socialHome,
    private: !social,
    path: integrationApplication.urlOverride!,
    state: SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE[slot.role],
  };
};

export const getWidgetsIdsFromSlots = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);

  return widgetSlots
    .map(({ pluginInfo }) => pluginInfo?.widgetId)
    .filter((widgetId): widgetId is WidgetId => !!widgetId);
};

export const getInstalledPluginsSlots = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);

  return widgetSlots.filter(({ pluginInfo }) => pluginInfo?.widgetId);
};

export const getInstalledWidgetsCompRefMap = async (
  editorSDK: FlowEditorSDK,
) => {
  const widgetSlots = await getInstalledPluginsSlots(editorSDK);

  return new Map(
    widgetSlots.map((widgetSlot) => [
      widgetSlot.pluginInfo!.widgetId,
      widgetSlot.compRef,
    ]),
  );
};

export const getInstalledWidgetPlugins = async (editorSDK: FlowEditorSDK) => {
  const flowAPI = globalAppState.getFlowAPI();
  const shouldUsePluginsFromRoutes = flowAPI?.experiments?.enabled(
    Experiment.InstalledWidgetsFromRoutes,
  );

  if (shouldUsePluginsFromRoutes) {
    const routes = await getRoutes(editorSDK);
    return routes.map(({ widgetId }) => ({
      widgetId,
      appDefinitionId: getAppDefIdFromWidgetId(widgetId),
    }));
  }

  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  const menuItemIds = await getWidgetIdsSetFromSubMenu(editorSDK);

  return widgetSlots.reduce<WidgetPluginPointer[]>(
    (pluginPointers, { pluginInfo }) => {
      const menuContainsItem =
        pluginInfo?.widgetId &&
        (menuItemIds.has(pluginInfo.widgetId) ||
          pluginInfo.widgetId === WidgetId.FollowingFollowers);

      return menuContainsItem
        ? [
            ...pluginPointers,
            {
              appDefinitionId: pluginInfo.appDefinitionId,
              widgetId: pluginInfo.widgetId,
            },
          ]
        : pluginPointers;
    },
    [],
  );
};

const debouncedNavigateToAddedWidget = debounce(
  async (editorSDK: FlowEditorSDK, widgetId: WidgetId): Promise<void> =>
    navigateToSection(editorSDK, widgetId),
  1000,
);

export const getEmptySlots = async (editorSDK: FlowEditorSDK) => {
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);

  return widgetSlots.filter(({ pluginInfo }) => !pluginInfo);
};

export const addWidgetsPlugins = async ({
  editorSDK,
  shouldNavigate = false,
  integrationApplications,
}: AddWidgetsPluginsContextProps): Promise<void> => {
  const flowAPI = globalAppState.getFlowAPI();
  const widgetRef = await getProfilePageBobWidgetRef(editorSDK);
  const widgetSlots = await getWidgetSlots(editorSDK, widgetRef);
  const addedWidgetsConfigs: AddedWidgetConfig[] = [];
  let emptySlots: WidgetSlot[];

  const shouldSearchRoutesForSlots = flowAPI?.experiments?.enabled(
    Experiment.AddWidgetsPluginsResilience,
  );

  if (shouldSearchRoutesForSlots) {
    const routes = await getRoutes(editorSDK);
    emptySlots = widgetSlots.filter(
      (slot) =>
        !routes.find(
          (route) =>
            route.state === SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE[slot.role],
        ),
    );
  } else {
    emptySlots = widgetSlots.filter(({ pluginInfo }) => !pluginInfo);
  }

  const addWidgetsAction = async () => {
    const addWidgetPromises = [];
    const notInstalledApplications = integrationApplications
      .filter((app) => (isPluginAlreadyAdded(widgetSlots, app) ? null : app))
      .filter((app) => !!app) as IntegrationApplication[];

    for (const app of notInstalledApplications) {
      const slot = emptySlots.shift();

      if (!slot) {
        log(
          `Members Area: all available slots are taken, widget ${app.widgetId} failed to install`,
        );
      } else {
        addWidgetPromises.push(
          (async () => {
            const config = await addWidgetPlugin(editorSDK, app, slot);
            addedWidgetsConfigs.push(config);
          })(),
        );
      }
    }

    return Promise.allSettled(addWidgetPromises);
  };

  await runAndWaitForApproval(editorSDK, addWidgetsAction);
  await addRoutesInAppProperties(editorSDK, addedWidgetsConfigs);
  await refreshApp(editorSDK);

  if (shouldNavigate && addedWidgetsConfigs.length === 1) {
    // Need to debounce the navigation because the app properties are not updated yet (refreshApp uses debounce inside)
    return debouncedNavigateToAddedWidget(
      editorSDK,
      addedWidgetsConfigs[0].widgetId,
    );
  }
};

const removeWidgetPlugin = async (
  editorSDK: FlowEditorSDK,
  slotCompRef: ComponentRef,
) => {
  return editorSDK.tpa.widgetPlugins.removeWidgetPlugin('', {
    slotCompRef,
  });
};

const getSlotsCompsRefsToRemove = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const slotsCompsRefs = await getInstalledWidgetsCompRefMap(editorSDK);

  return widgetsIds.reduce<ComponentRef[]>((componentRefs, widgetId) => {
    const slotCompRefToRemove = slotsCompsRefs.get(widgetId);

    return slotCompRefToRemove
      ? [...componentRefs, slotCompRefToRemove]
      : componentRefs;
  }, []);
};

export const removeWidgetsPlugins = async (
  editorSDK: FlowEditorSDK,
  widgetsIds: WidgetId[],
) => {
  const slotsCompsRefsToRemove = await getSlotsCompsRefsToRemove(
    editorSDK,
    widgetsIds,
  );

  for (const slotCompRef of slotsCompsRefsToRemove) {
    await removeWidgetPlugin(editorSDK, slotCompRef);
  }
};
