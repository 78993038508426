import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  getAppDefIdFromWidgetId,
  WidgetId,
} from '@wix/members-area-app-definitions';

import {
  getWidgetIdsFromSubMenu,
  removeMembersSubMenuItems,
} from '../../../editor/services/members-sub-menu';

type InstallationStatus = {
  [appDefinitionId: string]: boolean;
};

const isApplicationInstalled = async (
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
) => {
  const isInstalled = await editorSDK.application.isApplicationInstalled('', {
    appDefinitionId,
  });

  return (
    isInstalled || editorSDK.tpa.isApplicationInstalled('', { appDefinitionId })
  );
};

const getInstalledAppsWidgetsIds = async (
  editorSDK: FlowEditorSDK,
  widgetIds: WidgetId[],
) => {
  const uniqueAppDefIds = Array.from(
    new Set(widgetIds.map(getAppDefIdFromWidgetId)),
  );

  const installationCheckPromises = uniqueAppDefIds.map(
    async (appDefinitionId) => {
      const isInstalled = await isApplicationInstalled(
        editorSDK,
        appDefinitionId,
      );

      return { appDefinitionId, isInstalled };
    },
  );

  const installStatuses = await Promise.all(installationCheckPromises);

  const installationStatusMap = installStatuses.reduce<InstallationStatus>(
    (acc, { appDefinitionId, isInstalled }) => {
      acc[appDefinitionId] = isInstalled;
      return acc;
    },
    {},
  );

  return widgetIds.filter(
    (widgetId) => installationStatusMap[getAppDefIdFromWidgetId(widgetId)],
  );
};

export const syncMenuBasedOnInstalledApps = async (
  editorSDK: FlowEditorSDK,
) => {
  const widgetsIds = await getWidgetIdsFromSubMenu(editorSDK);
  const installedAppWidgetsIds = await getInstalledAppsWidgetsIds(
    editorSDK,
    widgetsIds,
  );

  const widgetIdsToRemove = widgetsIds.filter(
    (id) => !installedAppWidgetsIds.includes(id),
  );

  if (!widgetIdsToRemove.length) {
    return;
  }

  return removeMembersSubMenuItems(editorSDK, widgetIdsToRemove);
};
