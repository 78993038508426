import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WidgetId } from '@wix/members-area-app-definitions';

import { CommonSyncDataProps } from '../../../types';
import { determineSyncOperations } from './sync-utils';
import { getInstalledPluginsSlots } from '../../../editor/services/slots';
import { buildRoutesFromSlots } from '../../../editor/controller/routes-utils';
import { log } from '../../../editor/services/monitor';
import {
  getRoutes,
  removeRoutes,
  updateRoutesConfig,
} from '../../../editor/controller/controllers-routes';

type SyncRoutesWithMenuItems = CommonSyncDataProps & {
  widgetIdsFromRoutes: WidgetId[];
};

const addRoutes = async (
  editorSDK: FlowEditorSDK,
  widgetIdsToAdd: WidgetId[],
) => {
  const slots = await getInstalledPluginsSlots(editorSDK);
  const slotsToAdd = slots.filter(({ pluginInfo }) =>
    widgetIdsToAdd.includes(pluginInfo!.widgetId),
  );

  if (slotsToAdd.length) {
    const routesToAdd = await buildRoutesFromSlots(editorSDK, slotsToAdd);
    const currentRoutes = await getRoutes(editorSDK);

    return updateRoutesConfig(editorSDK, [...currentRoutes, ...routesToAdd]);
  }
};

export const syncRoutes = async ({
  editorSDK,
  widgetIdsFromRoutes,
  menuBasedWidgetIds,
}: SyncRoutesWithMenuItems) => {
  const { idsToAdd, idsToRemove } = determineSyncOperations({
    sourceOfTruth: menuBasedWidgetIds,
    syncTarget: widgetIdsFromRoutes,
  });

  if (idsToRemove.length) {
    await removeRoutes(editorSDK, idsToRemove);
  }

  if (idsToAdd.length) {
    await addRoutes(editorSDK, idsToAdd);
  }

  if (idsToAdd.length || idsToRemove.length) {
    log('editorReady: Syncing routes with menu items', {
      extra: {
        idsToAdd,
        idsToRemove,
        widgetIdsFromRoutes,
        menuBasedWidgetIds,
      },
    });
  }
};
